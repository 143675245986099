<!-- <template>
  <v-app>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App'
}
</script> -->

<template> 
  <div id="app"> 
    <v-app> 
      <v-app-bar v-if="deferredPrompt" color="blue-grey" dark fixed flat>
        <v-row>
          <v-col cols="12" md="12" class="text-center">
            <h5>¡Obtén nuestra aplicación gratuita!</h5>
            <v-btn class="mr-2"  rounded color="white" small @click="dismiss()"><span class="text-caption black--text">Cancelar</span></v-btn> 
            <v-btn class="ml-2"  rounded color="white" small @click="install()"><span class="text-caption black--text">Instalar</span></v-btn> 
          </v-col>
        </v-row>
      </v-app-bar>
      <!-- <v-banner v-if="deferredPrompt" color="blue-grey" dark class="mt-n2 text-center"> 
        <span class="text-caption">¡Obtén nuestra aplicación gratuita!</span>
        <div class="mt-1">
          <v-btn class="mr-2"  rounded color="white" x-small @click="dismiss()"><span class="text-caption black--text">Cancelar</span></v-btn> 
          <v-btn class="ml-2"  rounded color="white" x-small @click="install()"><span class="text-caption black--text">Instalar</span></v-btn> 
        </div>
      </v-banner> -->
      <v-main>
        <router-view/>
      </v-main>
    </v-app> 
  </div> 
</template>
<script> 
export default { 
  name: "App", 
  data() { 
    return { 
      deferredPrompt: null 
    }; 
  }, 
  created() { 
    window.addEventListener("beforeinstallprompt", e => { 
      e.preventDefault(); 
      // Oculte el evento para que pueda activarse más tarde. 
      this.deferredPrompt = e; 
    });
    window.addEventListener("appinstalled", () => { 
      this.deferredPrompt = null; 
    }); 
  }, 
  methods: { 
    async dismiss() { 
      this.deferredPrompt = null; 
    }, 
    async install() { 
      this.deferredPrompt.prompt(); 
    } 
  } 
}; 
</script>
